import styled, { css } from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';
import { defaultTheme, breakpoints } from '../styles';

const commonModifiers = {
  onDark: () => `
    color: ${defaultTheme.textOnDarkLight};
  `,
};

const baseStyles = css`
  color: ${defaultTheme.text};
  font-family: var(--primary-font);
`;

export const HeroCopy = styled.p`
  ${baseStyles};
  font-size: var(--primary-font-size-base);
  line-height: 1.4;
  letter-spacing: -0.6px;
  margin-bottom: 2rem;
  ${applyStyleModifiers(commonModifiers)};
`;

export const SectionItemCopy = styled.p`
  ${baseStyles};
  font-size: var(--primary-font-size-base);
  line-height: 1.65;
  letter-spacing: -0.2px;
  ${applyStyleModifiers(commonModifiers)};
`;

const sectionCopyModifiers = {
  end: () => `
    margin-bottom: 2rem;
  `,
};

export const SectionCopy = styled.p`
  ${baseStyles};
  font-size: var(--primary-font-size-base);
  line-height: 1.65;
  letter-spacing: -0.4px;
  margin-bottom: 1rem;
  @media (min-width: ${breakpoints.large}px) {
    font-size: calc(var(--primary-font-size-base) + 2px);
  }
  ${applyStyleModifiers(commonModifiers)};
  ${applyStyleModifiers(sectionCopyModifiers)};
`;
