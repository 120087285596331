/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';

const SVG = props => {
  return (
    <svg viewBox="0 0 28 28" {...props}>
      <rect fill="transparent" width="28" height="28" />
      <path d="M2.82,0,0,2.82,11.18,14,0,25.18,2.82,28,14,16.82,25.18,28,28,25.18,16.82,14,28,2.82,25.18,0,14,11.18Z" />
    </svg>
  );
};

export default SVG;
