/*  eslint-disable import/prefer-default-export */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { defaultTheme } from '../styles';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 1.75rem;
  cursor: pointer;
  user-select: none;
`;

const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  :checked ~ .checkmark {
    background: ${defaultTheme.blue};
  }
  :checked ~ .checkmark:after {
    display: block;
  }
`;

const Checkmark = styled.span`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  height: 24px;
  width: 24px;
  border: 1px solid ${defaultTheme.text};
  background: white;
  ::after {
    content: '';
    position: absolute;
    display: none;
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

export const Checkbox = ({ labelNode, name, value, onChange, onFocus, errorText }) => {
  //

  return (
    <Wrapper>
      <Label htmlFor={name}>
        <Input
          type="checkbox"
          name={name}
          id={name}
          value={value}
          errorText={errorText}
          onChange={onChange}
          onFocus={onFocus}
          checked={value}
        />
        <Checkmark className="checkmark" isError={errorText} />
        {labelNode}
      </Label>
    </Wrapper>
  );
};

Checkbox.propTypes = {
  labelNode: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  errorText: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
};

Checkbox.defaultProps = {
  errorText: '',
  onChange: () => {
    return null;
  },
  onFocus: () => {
    return null;
  },
};
