/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';
import { breakpoints } from '../styles';

const MODIFIERS = {
  large: () => `
    @media (min-width: ${breakpoints.medium}px) {
      padding: 9rem 0;
    }
    @media (min-width: ${breakpoints.large}px) {
      padding: 11rem 0;
    }
  `,
};

export const Block = styled.div`
  position: relative;
  padding: 5rem 0;
  @media (min-width: ${breakpoints.medium}px) {
    padding: 6.25rem 0;
  }
  @media (min-width: ${breakpoints.large}px) {
    padding: 7.5rem 0;
  }
  ${applyStyleModifiers(MODIFIERS)};
`;
