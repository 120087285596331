/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';

const SVG = props => {
  return (
    <svg width="20px" viewBox="0 0 40 40" {...props}>
      <path d="M4,40H36a4,4,0,0,0,4-4V4a4,4,0,0,0-4-4H4A4,4,0,0,0,0,4V36A4,4,0,0,0,4,40Zm6.67-21.33h8v-8h2.66v8h8v2.66h-8v8H18.67v-8h-8Z" />
    </svg>
  );
};

export default SVG;
