/* eslint-disable import/prefer-default-export */

import styled from 'styled-components';
import { motion } from 'framer-motion';
import { defaultTheme } from '../styles';

export const InputErrorMessage = styled(motion.div)`
  font-family: var(--primary-font);
  font-size: var(--primary-font-size-sublevel-1);
  color: #ffffff;
  position: absolute;
  top: ${props => props.topPosition}px;
  left: 0;
  padding: 4px 8px;
  border: 1px ${defaultTheme.red} solid;
  background: ${defaultTheme.red};
  cursor: pointer;
  &::after {
    content: '';
    position: absolute;
    bottom: -8px;
    left: ${props => props.arrowPosition}px;
    width: 0;
    height: 0;
    border-top: 8px solid ${defaultTheme.red};
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
  }
`;
