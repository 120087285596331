/* eslint-disable import/prefer-default-export */

import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../components/Layout';
import '../styles/global.css';
import { GlobalStyle } from '../components';

export const wrapPageElement = ({ element, props: { uri } }) => {
  return (
    <>
      <GlobalStyle />
      <Layout path={`/${uri.split('/')[1]}`}>
        <>{element}</>
      </Layout>
    </>
  );
};

wrapPageElement.propTypes = {
  element: PropTypes.node.isRequired,
  props: PropTypes.object.isRequired,
};
