/* eslint-disable import/prefer-default-export */

import styled from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';
import { breakpoints } from '../styles';

const maxWidth = breakpoints.maxWidth + 32;

const MODIFIERS = {
  marginless: () => `
    @media (max-width: ${maxWidth}px) {
      padding: 0;
    }
  `,
  tightMargin: () => `
    @media (max-width: ${maxWidth}px) {
      padding: 0 0.5rem;
    }
  `,
};

export const Container = styled.div`
  max-width: ${maxWidth}px;
  margin: 0 auto;
  position: relative;
  height: 100%;
  @media (max-width: ${maxWidth}px) {
    padding: 0 2rem;
  }
  ${applyStyleModifiers(MODIFIERS)};
`;
