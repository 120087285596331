/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';

const SVG = props => {
  return (
    <svg width="20px" viewBox="0 0 40 40" {...props}>
      <path d="M0,36a4,4,0,0,0,4,4H36a4,4,0,0,0,4-4V4a4,4,0,0,0-4-4H4A4,4,0,0,0,0,4ZM10.67,18.67H29.33v2.66H10.67Z" />
    </svg>
  );
};

export default SVG;
