/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { defaultTheme } from '../styles';

const Input = styled.input`
  font-family: var(--primary-font);
`;

export const PrimaryInput = styled(Input)`
  outline: none;
  width: 100%;
  background: #ffffff;
  border: none;
  padding: 0.75rem;
  font-size: var(--primary-font-size-base);
  color: #000000;
  border: 1px solid #e1e2eb;
  box-shadow: rgba(206, 209, 224, 0.2) 0 2px 4px 0 inset;
  margin-top: 0.25rem;
  ::placeholder {
    color: ${defaultTheme.textLight};
    font-size: 14px;
  }
`;

export const SecondaryInput = styled.input`
  font-family: var(--primary-font);
  outline: none;
  width: 100%;
  background: none;
  border: none;
  font-size: 14px;
  color: #ffffff;
  ::placeholder {
    color: #a0aec0;
  }
`;

export const TextArea = styled.textarea`
  font-family: var(--primary-font);
  font-size: var(--primary-font-size-base);
  outline: none;
  width: 100%;
  border: 1px solid #e1e2eb;
  box-shadow: rgba(206, 209, 224, 0.2) 0 2px 4px 0 inset;
  color: #000000;
  padding: 0.75rem;
  margin-top: 0.25rem;
  line-height: 1.5;
  ::placeholder {
    padding: 3px 0 0 0;
    color: ${defaultTheme.textLight};
    font-size: 14px;
  }
`;
