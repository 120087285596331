/* eslint-disable import/prefer-default-export */
import { createGlobalStyle } from 'styled-components';
import { primaryFont, secondaryFont, normalize } from '../styles';

export const GlobalStyle = createGlobalStyle`
${normalize};

  :root {
    --primary-font: ${primaryFont.name};
    --primary-font-size-base: ${primaryFont.defaultBaseSize}em;
    --primary-font-type-scale: ${primaryFont.defaultScale};
    --primary-font-size-level-1: calc(var(--primary-font-size-base) * var(--primary-font-type-scale));
    --primary-font-size-level-2: calc(var(--primary-font-size-level-1) * var(--primary-font-type-scale));
    --primary-font-size-level-3: calc(var(--primary-font-size-level-2) * var(--primary-font-type-scale));
    --primary-font-size-level-4: calc(var(--primary-font-size-level-3) * var(--primary-font-type-scale));
    --primary-font-size-level-5: calc(var(--primary-font-size-level-4) * var(--primary-font-type-scale));
    --primary-font-size-level-6: calc(var(--primary-font-size-level-5) * var(--primary-font-type-scale));
    --primary-font-size-level-7: calc(var(--primary-font-size-level-6) * var(--primary-font-type-scale));
    --primary-font-size-level-8: calc(var(--primary-font-size-level-7) * var(--primary-font-type-scale));
    --primary-font-size-level-9: calc(var(--primary-font-size-level-8) * var(--primary-font-type-scale));
    --primary-font-size-sublevel-1: calc(var(--primary-font-size-base) / var(--primary-font-type-scale));
    --primary-font-size-sublevel-2: calc(var(--primary-font-size-sublevel-1) / var(--primary-font-type-scale));
    --primary-font-weight-light: ${primaryFont.lightWeight};
    --primary-font-weight-regular: ${primaryFont.regularWeight};
    --primary-font-weight-bold: ${primaryFont.boldWeight};
    --primary-font-weight-black: ${primaryFont.blackWeight};

    --secondary-font: ${secondaryFont.name};
    --secondary-font-size-base: ${secondaryFont.defaultBaseSize}em;
    --secondary-font-type-scale: ${secondaryFont.defaultScale};
    --secondary-font-size-level-1: calc(var(--secondary-font-size-base) * var(--secondary-font-type-scale));
    --secondary-font-size-level-2: calc(var(--secondary-font-size-level-1) * var(--secondary-font-type-scale));
    --secondary-font-size-level-3: calc(var(--secondary-font-size-level-2) * var(--secondary-font-type-scale));
    --secondary-font-size-level-4: calc(var(--secondary-font-size-level-3) * var(--secondary-font-type-scale));
    --secondary-font-size-level-5: calc(var(--secondary-font-size-level-4) * var(--secondary-font-type-scale));
    --secondary-font-size-level-6: calc(var(--secondary-font-size-level-5) * var(--secondary-font-type-scale));
    --secondary-font-size-level-7: calc(var(--secondary-font-size-level-6) * var(--secondary-font-type-scale));
    --secondary-font-size-level-8: calc(var(--secondary-font-size-level-7) * var(--secondary-font-type-scale));
    --secondary-font-size-level-9: calc(var(--secondary-font-size-level-8) * var(--secondary-font-type-scale));
    --secondary-font-size-sublevel-1: calc(var(--secondary-font-size-base) / var(--secondary-font-type-scale));
    --secondary-font-size-sublevel-2: calc(var(--secondary-font-size-sublevel-1) / var(--secondary-font-type-scale));
    --secondary-font-weight-light: ${secondaryFont.lightWeight};
    --secondary-font-weight-regular: ${secondaryFont.regularWeight};
    --secondary-font-weight-bold: ${secondaryFont.boldWeight};
    --secondary-font-weight-black: ${secondaryFont.blackWeight};

    --elevation-1: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    --elevation-2: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    --elevation-3: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    --elevation-4: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);

    --system-font: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  }
`;
