import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { motion, AnimatePresence } from 'framer-motion';
import { Link } from 'gatsby';
import Locky from 'react-locky';
import HamburgerIcon from './Svg/Hamburger';
import { defaultTheme } from '../styles';

const menuAnimation = {
  hidden: {
    opacity: 0,
    transition: {
      ease: 'easeInOut',
      duration: 0.1,
    },
  },
  visible: {
    opacity: 1,
    transition: {
      ease: 'easeInOut',
      duration: 0.1,
    },
  },
};

const parentAnimation = {
  visible: {
    opacity: 1,
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.1,
      duration: 0.1,
    },
  },
  hidden: {
    opacity: 0,
    transition: {
      when: 'afterChildren',
    },
  },
};
const childrenAnimation = {
  visible: { opacity: 1, x: 0 },
  hidden: { opacity: 0, x: -16 },
};

const Button = styled.button`
  position: relative;
  z-index: 999;
  background: none;
  border: none;
  margin-left: 10px;
  padding: 0 15px 0;
  cursor: pointer;
  :focus {
    outline: 0;
  }
  @media (min-width: ${props => props.menuSwitchWidth}) {
    display: ${props => (props.menuOpen ? 'block' : 'none')};
  }
`;

const Menu = styled(motion.div)`
  position: fixed;
  background: #ffffff;
  z-index: 998;
  width: 100%;
  height: 100vh;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: ${props => (props.open ? 'auto' : 'none')};
  padding: 6rem 1rem 0;
`;

const LinkContainer = styled(motion.div)`
  color: ${defaultTheme.text};
  font-family: var(--primary-font);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -100%);
  font-size: var(--primary-font-size-level-3);
  font-weight: var(--primary-font-weight-bold);
  div {
    margin-bottom: 1.5rem;
  }
`;

const MobilMenu = ({ onClick, isOpen, menuSwitchWidth }) => {
  //

  return (
    <>
      <Button
        aria-label="mobile menu button"
        type="button"
        onClick={onClick}
        menuOpen={isOpen}
        menuSwitchWidth={menuSwitchWidth}
        data-locky-group="mobileNav"
      >
        <HamburgerIcon className="responsiveSvgColor" />
      </Button>
      <AnimatePresence>
        {isOpen && (
          <Locky group="mobileNav">
            <Menu open={isOpen} initial="hidden" animate="visible" exit="hidden" variants={menuAnimation}>
              <LinkContainer initial="hidden" animate="visible" variants={parentAnimation}>
                <motion.div variants={childrenAnimation}>
                  <Link onClick={onClick} to="/">
                    Home
                  </Link>
                </motion.div>
                <motion.div variants={childrenAnimation}>
                  <Link onClick={onClick} to="/products">
                    Our Products
                  </Link>
                </motion.div>
                <motion.div variants={childrenAnimation}>
                  <Link onClick={onClick} to="/crops">
                    Crops
                  </Link>
                </motion.div>
                <motion.div variants={childrenAnimation}>
                  <Link onClick={onClick} to="/about">
                    About Us
                  </Link>
                </motion.div>
                <motion.div variants={childrenAnimation}>
                  <Link onClick={onClick} to="/contact">
                    Contact Us
                  </Link>
                </motion.div>
              </LinkContainer>
            </Menu>
          </Locky>
        )}
      </AnimatePresence>
    </>
  );
};

export default MobilMenu;

MobilMenu.propTypes = {
  onClick: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  menuSwitchWidth: PropTypes.string.isRequired,
};
