/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';

const SVG = props => {
  return (
    <svg width="14px" viewBox="0 0 40 40" {...props}>
      <path d="M17.26,10.77H3.43A1.19,1.19,0,0,0,2.22,12.1L4.94,38.67A1.57,1.57,0,0,0,6.41,40H28.12a1.49,1.49,0,0,0,1.47-1.33L32.3,12.1a1.17,1.17,0,0,0-1.2-1.33H17.26Z" />
      <path d="M23.89,3.34A1.34,1.34,0,0,1,22.56,2V1.33A1.31,1.31,0,0,0,21.22,0H13.3A1.31,1.31,0,0,0,12,1.33V2a1.35,1.35,0,0,1-1.34,1.34H1.33A1.36,1.36,0,0,0,0,4.67v2.8A1.36,1.36,0,0,0,1.33,8.81h32a1.34,1.34,0,0,0,1.34-1.34V4.67a1.34,1.34,0,0,0-1.34-1.33Z" />
    </svg>
  );
};

export default SVG;
