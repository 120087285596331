/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';

const SVG = props => {
  return (
    <svg width="24px" viewBox="0 0 40 40" {...props}>
      <g fill="#ffffff">
        <path d="M26,15.43A2.57,2.57,0,0,0,24.57,14a4,4,0,0,0-1.44-.27c-.81,0-1.06,0-3.13,0s-2.32,0-3.13,0a4,4,0,0,0-1.44.27A2.57,2.57,0,0,0,14,15.43a4,4,0,0,0-.27,1.44c0,.81,0,1.06,0,3.13s0,2.32,0,3.13A4,4,0,0,0,14,24.57,2.57,2.57,0,0,0,15.43,26a4,4,0,0,0,1.44.27c.81,0,1.06.05,3.13.05s2.32,0,3.13-.05A4,4,0,0,0,24.57,26,2.57,2.57,0,0,0,26,24.57a4,4,0,0,0,.27-1.44c0-.81.05-1.06.05-3.13s0-2.32-.05-3.13A4,4,0,0,0,26,15.43ZM20,24a4,4,0,1,1,4-4A4,4,0,0,1,20,24Zm4.14-7.19a.93.93,0,0,1,0-1.86.93.93,0,0,1,0,1.86Z" />
        <circle cx="20" cy="20" r="2.58" />
        <path d="M20,0A20,20,0,1,0,40,20,20,20,0,0,0,20,0Zm7.71,23.2a5.79,5.79,0,0,1-.36,1.88,4,4,0,0,1-2.27,2.27,5.79,5.79,0,0,1-1.88.36c-.83,0-1.09,0-3.2,0s-2.37,0-3.2,0a5.79,5.79,0,0,1-1.88-.36,4,4,0,0,1-2.27-2.27,5.79,5.79,0,0,1-.36-1.88c0-.83,0-1.09,0-3.2s0-2.37,0-3.2a5.79,5.79,0,0,1,.36-1.88,4,4,0,0,1,2.27-2.27,5.79,5.79,0,0,1,1.88-.36c.83,0,1.09,0,3.2,0s2.37,0,3.2,0a5.79,5.79,0,0,1,1.88.36,4,4,0,0,1,2.27,2.27,5.79,5.79,0,0,1,.36,1.88c0,.83,0,1.09,0,3.2S27.74,22.37,27.71,23.2Z" />
      </g>
    </svg>
  );
};

export default SVG;
