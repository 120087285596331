import styled, { css } from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';
import { breakpoints, defaultTheme } from '../styles';

const optionInfoModifiers = {
  noBorder: () => `
    background: ${defaultTheme.productImgBg};
    border: none;
    box-shadow: none;
  `,
};

export const OptionInfo = styled.div`
  display: inline-block;
  font-family: var(--primary-font);
  font-size: var(--primary-font-size-sublevel-1);
  border: 1px ${defaultTheme.blue} solid;
  padding: 6px 12px;
  background: #ffffff;
  box-shadow: var(--elevation-2);
  @media (min-width: ${breakpoints.large}px) {
    font-size: var(--primary-font-size-base);
  }
  ${applyStyleModifiers(optionInfoModifiers)};
`;

/* Pricing */

export const DicscountLabel = styled.div`
  position: absolute;
  padding: 6px 12px;
  font-family: var(--primary-font);
  background: ${defaultTheme.yellow};
  color: #000000;
  top: 8px;
  left: 8px;
  font-size: 11px;
  border-radius: 4px;
  overflow: hidden;
  @media (min-width: ${breakpoints.large}px) {
    font-size: 13px;
  }
`;

export const DiscountedPrice = styled.span`
  color: #718096;
  font-family: var(--primary-font);
  font-size: var(--primary-font-size-sublevel-1);
`;

const priceModifiers = {
  large: () => `
    span {
    font-size: 1.7em;
    font-weight: var(--primary-font-weight-bold);
  }
  `,
};

export const Price = styled.span`
  font-family: var(--primary-font);
  font-size: 12px;
  color: ${defaultTheme.blue};
  sub {
    font-size: 12px;
    color: ${defaultTheme.text};
  }
  span {
    font-size: 1.5em;
    color: #000000;
  }
  strong {
    font-size: 2em;
    color: #000000;
    font-weight: var(--primary-font-weight-bold);
  }
  ${applyStyleModifiers(priceModifiers)};
`;

/* Copy */

const baseStyles = css`
  color: ${defaultTheme.text};
  font-family: var(--primary-font);
`;

export const ProductDescriptionCopy = styled.p`
  ${baseStyles};
  font-size: 14px;
  line-height: 1.65;
  letter-spacing: -0.2px;
  max-width: 300px;
`;

/* Buttons */

export const CartButton = styled.button`
  display: inline-flex;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  svg {
    transition: 0.3s;
    fill: ${defaultTheme.blue};
  }
  &:focus {
    outline: 0;
  }
  &:hover {
    svg {
      fill: #2c5282;
    }
  }
  :disabled {
    svg {
      fill: #a0aec0;
    }
    cursor: not-allowed;
  }
`;

/* Mdx Components */

export const ProductMdx = styled.div`
  font-family: var(--primary-font);
  h2 {
    color: ${defaultTheme.text};
    font-size: var(--primary-font-size-level-1);
    font-weight: var(--primary-font-weight-bold);
    line-height: 1.3;
    margin-bottom: 0.5rem;
  }
  h3 {
    color: ${defaultTheme.text};
    font-size: var(--primary-font-size-base);
    font-weight: var(--primary-font-weight-bold);
    line-height: 1.3;
    margin-bottom: 0.5rem;
  }
  p {
    color: ${defaultTheme.text};
    font-size: var(--primary-font-size-base);
    line-height: 1.5;
    letter-spacing: -0.4px;
    margin-bottom: 2rem;
    :last-of-type {
      margin-bottom: 2rem;
    }
  }
  ul {
    margin-bottom: 2rem;
    list-style-type: disc;
    list-style-position: outside;
    li {
      margin-left: -1rem;
      line-height: 1.5;
    }
  }
  table {
    width: 100%;
    line-height: 1.5;
    border-collapse: collapse;
    /* tr:nth-of-type(1) {
      color: ${defaultTheme.red};
    } */
    tr {
      border-bottom: 1px #e2e8f0 solid;
      :last-of-type {
        border-bottom: none;
      }
    }
    td {
      padding: 1rem 2rem 0.5rem 0;
      vertical-align: top;
    }
    &.col-2 {
      @media (min-width: 960px) {
        td:nth-of-type(1) {
          width: 498px;
        }
      }
    }
  }
`;

export const Div = styled.div``;

export const TableWrapper = styled.div`
  padding: 2rem 1rem;
  background: #f7fafc;
  margin-top: 1rem;
  margin-bottom: 6rem;
  overflow-x: auto;
  @media (min-width: 960px) {
    padding: 2rem 2rem;
  }
`;

export const SectionWith2Grids = styled.div`
  display: grid;
  grid-column-gap: 6rem;
  max-width: 600px;
  margin: 0 auto;
  @media (min-width: ${breakpoints.medium}px) {
  }
  @media (min-width: 960px) {
    max-width: 960px;
    grid-template-columns: 1fr 1fr;
  }
`;

export const SectionWith0Grid = styled.div`
  max-width: 600px;
  margin: 0 auto;
  @media (min-width: 960px) {
    max-width: 960px;
  }
`;

export const ProductName = styled.h3`
  font-family: var(--primary-font);
  font-size: var(--primary-font-size-base);
  font-weight: var(--primary-font-weight-bold);
  color: ${defaultTheme.text};
  @media (min-width: ${breakpoints.large}px) {
    font-size: var(--primary-font-size-level-1);
  }
`;

export const Updating = styled.span`
  display: inline-block;
  font-family: var(--primary-font);
  font-size: var(--primary-font-size-sublevel-1);
  color: #000000;
`;

export const VariantQuantityInput = styled.input`
  font-family: var(--primary-font);
  font-size: var(--primary-font-size-base);
  font-weight: var(--primary-font-weight-bold);
  background: ${defaultTheme.blue};
  color: #ffffff;
  width: 52px;
  outline: none;
  padding: 8px 8px;
  border: 1px ${defaultTheme.blue} solid;
  ::placeholder {
    color: #ffffff;
  }
  &:focus {
    ::placeholder {
      color: transparent;
    }
  }
  text-align: center;
  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 0;
  justify-content: center;
  line-height: 24px;
  color: ${defaultTheme.text};
  background: ${props => (props.isCartUpdating ? '#a0aec0' : '#ffffff')};
  border-bottom: 1px ${defaultTheme.hr} solid;
  transition: 0.4s;
  svg {
    fill: #000000;
    margin-left: 0.25rem;
    height: 24px;
  }
`;
