/* eslint-disable import/prefer-default-export */

const initialState = {
  isCartOpen: false,
  checkoutCart: {},
  isProductSelectorOpen: false,
  variants: [],
  isCartUpdating: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SET_SHOPIFY_CLIENT':
      return { ...state, client: action.client };
    case 'UPDATE_CHECKOUT_CART':
      return { ...state, checkoutCart: action.checkoutCart };
    case 'TOGGLE_CART_DRAWER':
      return { ...state, isCartOpen: !state.isCartOpen };
    case 'TOGGLE_PRODUCT_SELECTOR':
      return {
        ...state,
        isProductSelectorOpen: !state.isProductSelectorOpen,
        variants: action.variants,
        productName: action.productName,
      };
    case 'SYNCING_CART':
      return { ...state, isCartUpdating: action.status };
    default:
      return { ...state };
  }
};
