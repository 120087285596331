// "serif", "sans-serif", "cursive", "fantasy", "monospace"
export const primaryFont = {
  name: 'Metropolis',
  type: 'sans-serif',
  defaultBaseSize: 1,
  mediumBaseSize: 1,
  largeBaseSize: 1,
  defaultScale: 1.25,
  mediumScale: 1,
  largeScale: 1,
  lightWeight: 300,
  regularWeight: 400,
  boldWeight: 600,
  blackWeight: 900,
};

export const secondaryFont = {
  name: 'Georgia',
  type: 'serif',
  defaultBaseSize: 1,
  mediumBaseSize: 1,
  largeBaseSize: 1,
  defaultScale: 1.25,
  mediumScale: 1,
  largeScale: 1,
  lightWeight: 400,
  regularWeight: 400,
  boldWeight: 600,
  blackWeight: 600,
};
