import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import { Container } from './Container';
import { defaultTheme } from '../styles';
import { slugify, useSiteMetadata } from '../utils';
import { Newsletter } from './Newsletter';
import FacebookIcon from './Svg/FacebookCircle';
import InstagramIcon from './Svg/InstagramCircle';
import { SecondaryLinkGatsby as Link, SecondaryLinkAnchor as Anchor } from './Anchors';

const QUERY = graphql`
  query {
    allShopifyProduct(sort: { fields: title, order: ASC }) {
      edges {
        ...ShopifyProducts
      }
    }
  }
`;

const Wrapper = styled.footer`
  background: ${defaultTheme.blue};
  padding: 5rem 0 2rem;
`;

const FooterSubHeaders = styled.h2`
  color: #ffffff;
  font-size: 16px;
  font-family: var(--primary-font);
  text-transform: uppercase;
  margin-bottom: 1.25rem;
  font-weight: var(--primary-font-weight-bold);
`;

const CopyrightText = styled.div`
  color: ${defaultTheme.textOnDarkLight};
  font-family: var(--primary-font);
  font-size: var(--primary-font-size-sublevel-1);
  margin-top: 4rem;
  text-align: center;
  line-height: 1.5;
  span {
    font-weight: var(--primary-font-weight-bold);
    color: ${defaultTheme.textOnDark};
  }
`;

const AnchorWrapper = styled.div`
  margin-bottom: 0.5rem;
`;

const Grid = styled.div`
  display: grid;
  grid-row-gap: 4rem;
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr;
    > div:not(:last-child) {
      border-right: 1px ${defaultTheme.hrOnDark} solid;
    }
    > div {
      padding-left: 2rem;
    }
  }
`;

const Footer = () => {
  //
  const {
    allShopifyProduct: { edges: products },
  } = useStaticQuery(QUERY);

  const { siteName, contact, socialMedia } = useSiteMetadata();

  const {
    address: { streetAddress, streetAddressAdditional, addressCountryLong, addressLocality, addressRegion, postalCode },
    googleMap,
  } = contact;

  const year = new Date().getFullYear();

  return (
    <Wrapper>
      <Container>
        <Grid>
          <div>
            <FooterSubHeaders>Products</FooterSubHeaders>
            {products.map(product => {
              return (
                <AnchorWrapper key={product.node.id}>
                  <Link to={`/products/${slugify(product.node.title)}`} modifiers={['onDark']}>
                    {product.node.title}
                  </Link>
                </AnchorWrapper>
              );
            })}
          </div>
          <div>
            <FooterSubHeaders>Contact Us</FooterSubHeaders>
            <div style={{ marginBottom: '2rem' }}>
              <Anchor
                href={googleMap}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="address"
                modifiers={['onDark']}
              >
                {streetAddress},
                <br />
                {streetAddressAdditional},
                <br />
                {`${postalCode}, ${addressLocality}`},
                <br />
                {`${addressRegion}, ${addressCountryLong}`}
              </Anchor>
            </div>
            <FooterSubHeaders>Legal</FooterSubHeaders>
            <AnchorWrapper>
              <Link to="/privacy-policy" modifiers="onDark">
                Privacy Policy
              </Link>
            </AnchorWrapper>
            <AnchorWrapper>
              <Link to="/terms-of-use" modifiers="onDark">
                Terms of Use
              </Link>
            </AnchorWrapper>
            <AnchorWrapper>
              <Link to="/cookie-policy" modifiers="onDark">
                Cookie Policy
              </Link>
            </AnchorWrapper>
          </div>
          <div>
            <FooterSubHeaders>Subscribe</FooterSubHeaders>
            <div style={{ marginBottom: '2rem' }}>
              <Newsletter />
            </div>
            <FooterSubHeaders>Follow Us</FooterSubHeaders>
            <div style={{ marginBottom: '2rem', display: 'flex', maxWidth: '60px', justifyContent: 'space-between' }}>
              <a href={socialMedia.facebook} target="_blank" rel="noopener noreferrer" aria-label="facebook">
                <FacebookIcon />
              </a>
              <a href={socialMedia.instagram} target="_blank" rel="noopener noreferrer" aria-label="facebook">
                <InstagramIcon />
              </a>
            </div>
            <FooterSubHeaders>Site Map</FooterSubHeaders>
            <AnchorWrapper>
              <Link to="/" modifiers={['onDark']}>
                Home
              </Link>
            </AnchorWrapper>
            <AnchorWrapper>
              <Link to="/about" modifiers={['onDark']}>
                About Us
              </Link>
            </AnchorWrapper>
            <AnchorWrapper>
              <Link to="/contact" modifiers={['onDark']}>
                Contact Form
              </Link>
            </AnchorWrapper>
          </div>
        </Grid>
        <CopyrightText>
          Copyright &copy; <span>{siteName}</span> {year}. All Rights Reserved.
        </CopyrightText>
      </Container>
    </Wrapper>
  );
};

export default Footer;
