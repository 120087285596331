import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { motion, AnimatePresence } from 'framer-motion';
import Locky from 'react-locky';
import {
  addProductToCart,
  decreaseProductQuantity,
  updateProductQuantity,
  removeProductFromCart,
} from '../redux/actions';
import { defaultTheme } from '../styles';
import AddIcon from './Svg/Add';
import RemoveIcon from './Svg/Remove';
import CancelIcon from './Svg/Cancel';
import Spinner from './Svg/Spinner';
import {
  CartButton as Button,
  ProductName,
  Updating,
  OptionInfo,
  DiscountedPrice as Discount,
  Price as DisplayPrice,
  VariantQuantityInput,
  Header,
} from './ProductSubComponents';
import { Overlay } from './Overlay';
import { thousandSeparator } from '../utils';

const selectionAnimation = {
  hidden: {
    opacity: 0,
    transform: 'translate(-50%, -40%)',
    transition: {
      duration: 0.2,
    },
  },
  open: {
    opacity: 1,
    transform: 'translate(-50%, -50%)',
    transition: {
      duration: 0.2,
    },
  },
};

const VariantType = styled(OptionInfo)``;

const DiscountedPrice = styled(Discount)`
  margin-left: 0.5rem;
`;

const Price = styled(DisplayPrice)`
  margin-left: 0.5rem;
`;

const SelectionWraper = styled(motion.div)`
  padding-bottom: 2rem;
  position: fixed;
  z-index: 9001;
  background: #f7fafc;
  box-shadow: var(--elevation-4);
  display: grid;
  grid-row-gap: 1rem;
  left: 50%;
  top: 50%;
  min-width: 300px;
  border-radius: 8px;
  overflow: hidden;
`;

const VariantGroup = styled.div`
  > div {
    border-bottom: 1px ${defaultTheme.hr} solid;
  }
  > div:last-child {
    border-bottom: none;
  }
`;

const Variant = styled.div`
  padding: 1.5rem 0 1rem;
  @media (min-width: 480px) {
    width: 400px;
    padding: 1.5rem 2rem 1rem;
  }
`;

const ButtonGroup = styled.div`
  display: grid;
  grid-template-columns: 24px 60px 24px 1fr;
  align-items: center;
  justify-items: center;
`;

const VariantData = styled.div`
  margin-bottom: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SubTotal = styled.div`
  justify-self: end;
`;

const ProductSelector = () => {
  //
  const dispatch = useDispatch();
  const { isProductSelectorOpen, checkoutCart, client, variants, productName, isCartUpdating } = useSelector(
    state => state.shopify
  );

  const decreaseButton = shopifyId => {
    const result = checkoutCart.lineItems.find(lineItem => {
      return lineItem.variant.id === shopifyId;
    });
    if (result) {
      return (
        <Button
          type="button"
          disabled={isCartUpdating}
          onClick={() => dispatch(decreaseProductQuantity(client, checkoutCart, result.id, result.quantity))}
        >
          <RemoveIcon />
        </Button>
      );
    }
    return (
      <Button type="button" disabled>
        <RemoveIcon />
      </Button>
    );
  };

  const confirmUpdate = (newQuantity, currentQuantity, shopifyId, lineItemId) => {
    //
    if (newQuantity && Number(newQuantity) !== currentQuantity && lineItemId) {
      dispatch(updateProductQuantity(client, checkoutCart, lineItemId, Number(newQuantity)));
    }
    if (newQuantity && Number(newQuantity) !== currentQuantity && !lineItemId) {
      dispatch(addProductToCart(shopifyId, client, checkoutCart, Number(newQuantity)));
    }
  };

  const handleKeyDown = (e, currentQuantity, shopifyId, lineItemId) => {
    if (e.key === 'Enter') {
      e.target.placeholder = e.target.value;
      e.target.blur();
      confirmUpdate(e.target.value, currentQuantity, shopifyId, lineItemId);
    }
  };

  const quantity = shopifyId => {
    const result = checkoutCart.lineItems.find(lineItem => {
      return lineItem.variant.id === shopifyId;
    });

    let currentQuantity = 0;
    let lineItemId = null;
    if (result) {
      currentQuantity = result.quantity;
      lineItemId = result.id;
    }

    return (
      <div>
        <VariantQuantityInput
          type="number"
          placeholder={currentQuantity}
          onBlur={e => {
            confirmUpdate(e.target.value, currentQuantity, shopifyId, lineItemId);
            e.target.placeholder = e.target.value || currentQuantity;
            e.target.value = '';
          }}
          onKeyDown={e => {
            handleKeyDown(e, currentQuantity, shopifyId, lineItemId);
          }}
        />
      </div>
    );
  };

  const subtotal = (shopifyId, price) => {
    //
    if (checkoutCart.lineItems) {
      const result = checkoutCart.lineItems.find(lineItem => {
        return lineItem.variant.id === shopifyId;
      });

      if (result !== undefined) {
        return (
          <Price>
            MYR <strong>{thousandSeparator(result.quantity * Number(price))}</strong>
          </Price>
        );
      }
    }

    if (!checkoutCart.lineItems) {
      return (
        <Price>
          <sub>MYR 0</sub>
        </Price>
      );
    }
  };

  const cancel = shopifyId => {
    const result = checkoutCart.lineItems.find(lineItem => {
      return lineItem.variant.id === shopifyId;
    });
    if (result) {
      return (
        <Button
          type="button"
          disabled={isCartUpdating}
          onClick={() => {
            dispatch(removeProductFromCart(client, checkoutCart, result.id));
          }}
        >
          <CancelIcon />
        </Button>
      );
    }
    return (
      <Button type="button" disabled>
        <CancelIcon />
      </Button>
    );
  };

  return (
    <>
      <AnimatePresence>
        {isProductSelectorOpen && (
          <Locky>
            <SelectionWraper initial="hidden" animate="open" exit="hidden" variants={selectionAnimation}>
              <Header isCartUpdating={isCartUpdating}>
                {isCartUpdating ? (
                  <>
                    <Updating>Updating cart</Updating>
                    <Spinner />
                  </>
                ) : (
                  <ProductName>{productName}</ProductName>
                )}
              </Header>
              <VariantGroup>
                {variants.map(variant => {
                  return (
                    <Variant key={variant.id}>
                      <div style={{ padding: '0 1rem' }}>
                        <VariantData>
                          <div>
                            <VariantType>{variant.title !== 'Default Title' && variant.title}</VariantType>
                            {variant.compareAtPrice && Number(variant.compareAtPrice) > 0 && (
                              <DiscountedPrice>
                                <strike>MYR ${variant.compareAtPrice.slice(0, -3)}</strike>
                              </DiscountedPrice>
                            )}
                            <Price>
                              MYR <span>{variant.price.slice(0, -3)}</span> <sub>each</sub>
                            </Price>
                          </div>
                          <div>{checkoutCart && checkoutCart.lineItems && cancel(variant.shopifyId)}</div>
                        </VariantData>
                        <ButtonGroup>
                          <div>{checkoutCart && checkoutCart.lineItems && decreaseButton(variant.shopifyId)}</div>
                          <div>{checkoutCart && checkoutCart.lineItems && quantity(variant.shopifyId)}</div>
                          <div>
                            <Button
                              type="button"
                              disabled={isCartUpdating}
                              onClick={() => {
                                dispatch(addProductToCart(variant.shopifyId, client, checkoutCart));
                              }}
                            >
                              <AddIcon />
                            </Button>
                          </div>
                          <SubTotal>{subtotal(variant.shopifyId, variant.price)}</SubTotal>
                        </ButtonGroup>
                      </div>
                    </Variant>
                  );
                })}
              </VariantGroup>
            </SelectionWraper>
            <Overlay onClick={() => dispatch({ type: 'TOGGLE_PRODUCT_SELECTOR' })} />
          </Locky>
        )}
      </AnimatePresence>
    </>
  );
};

export default ProductSelector;
