/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';

const SVG = props => {
  return (
    <svg viewBox="0 0 15 28" {...props}>
      <g>
        <path d="M14.91,4.82,12.09,2,.09,14l12,12,2.82-2.82L5.75,14Z" />
      </g>
    </svg>
  );
};

export default SVG;
