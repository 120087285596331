/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { breakpoints } from '../styles';

export const MarkdownStyles = styled.div`
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: Georgia, serif;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: -apple-system, BlinkMacSystemFont, “Segoe UI”, “Roboto”, “Oxygen”, “Ubuntu”, “Cantarell”, “Fira Sans”,
      “Droid Sans”, “Helvetica Neue”, sans-serif;
  }

  h1 {
    font-size: var(--primary-font-size-level-4);
    line-height: 1.35;
    margin-top: 4rem;
    margin-bottom: 0.25rem;
    font-weight: var(--primary-font-weight-black);
  }
  h2 {
    font-size: var(--primary-font-size-level-3);
    line-height: 1.35;
    margin-top: 4rem;
    margin-bottom: 0.25rem;
    font-weight: var(--primary-font-weight-bold);
  }

  h3 {
    font-size: var(--primary-font-size-level-2);
    line-height: 1.35;
    margin-top: 4rem;
    margin-bottom: 0.25rem;
    font-weight: var(--primary-font-weight-bold);
  }

  h4,
  h5,
  h6 {
    font-size: var(--primary-font-size-base);
    line-height: 1.5;
    margin-top: 4rem;
    margin-bottom: 0.25rem;
    font-weight: var(--primary-font-weight-bold);
  }

  p {
    font-family: var(--secondary-font);
    font-size: var(--primary-font-size-base);
    line-height: 1.5;
    margin-bottom: 1rem;
    @media (min-width: ${breakpoints.large}px) {
      font-size: calc(var(--primary-font-size-base) * 1.25);
    }
  }

  strong {
    font-weight: var(--primary-font-weight-bold);
  }

  em {
    font-style: italic;
  }

  s {
  }

  blockquote {
  }

  ul {
    list-style-type: square;
    list-style-position: outside;
    padding-top: 1rem;
    margin-bottom: 2rem;
  }

  ol {
    list-style-type: decimal;
    list-style-position: outside;
    padding-top: 1rem;
    margin-bottom: 2rem;
  }

  li {
    margin-bottom: 1rem;
    line-height: 1.5;
  }

  pre {
    display: block;
    font-family: monospace;
    white-space: pre-wrap;
    padding: 1rem;
    margin-bottom: 2rem;
  }

  code {
    font-family: monospace;
  }

  a {
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    word-break: break-word;
    outline: 0;
    font-weight: bolder;
    color: #000000;
    box-shadow: #000000 0 -1px 0 inset;
    :hover {
    }
    cursor: pointer;
  }

  figcaption {
    font-family: -apple-system, BlinkMacSystemFont, “Segoe UI”, “Roboto”, “Oxygen”, “Ubuntu”, “Cantarell”, “Fira Sans”,
      “Droid Sans”, “Helvetica Neue”, sans-serif;
    text-align: center;
    margin-top: 0.5rem;
    color: #4a5568;
    font-size: 0.875rem;
    a {
      font-weight: 500;
      color: #000000;
    }
  }

  figure {
    margin-bottom: 2.5rem;
    img {
      width: 100%;
      box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    }
  }

  hr {
    margin-bottom: 2rem;
  }
`;
