/* eslint-disable import/prefer-default-export */

import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import Client from 'shopify-buy';
import { initializeCheckoutCart, setClient } from './actions';
import combinedReducers from './reducers';

const isBrowser = typeof window !== 'undefined';

const client = Client.buildClient({
  domain: `${process.env.GATSBY_SHOPIFY_SHOP_NAME}.myshopify.com`,
  storefrontAccessToken: process.env.GATSBY_SHOPIFY_ACCESS_TOKEN,
});

export const store = createStore(combinedReducers, applyMiddleware(thunkMiddleware));

store.dispatch(setClient(client));
store.dispatch(initializeCheckoutCart(isBrowser, client));
