/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';

const SVG = props => {
  return (
    <svg viewBox="0 0 100 100" {...props}>
      <g transform="rotate(0 50 50)">
        <rect x="47" y="22" rx="3" ry="4" width="6" height="8">
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8s"
            begin="-0.7111111111111111s"
            repeatCount="indefinite"
          />
        </rect>
      </g>
      <g transform="rotate(40 50 50)">
        <rect x="47" y="22" rx="3" ry="4" width="6" height="8">
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8s"
            begin="-0.6222222222222222s"
            repeatCount="indefinite"
          />
        </rect>
      </g>
      <g transform="rotate(80 50 50)">
        <rect x="47" y="22" rx="3" ry="4" width="6" height="8">
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8s"
            begin="-0.5333333333333333s"
            repeatCount="indefinite"
          />
        </rect>
      </g>
      <g transform="rotate(120 50 50)">
        <rect x="47" y="22" rx="3" ry="4" width="6" height="8">
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8s"
            begin="-0.4444444444444444s"
            repeatCount="indefinite"
          />
        </rect>
      </g>
      <g transform="rotate(160 50 50)">
        <rect x="47" y="22" rx="3" ry="4" width="6" height="8">
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8s"
            begin="-0.35555555555555557s"
            repeatCount="indefinite"
          />
        </rect>
      </g>
      <g transform="rotate(200 50 50)">
        <rect x="47" y="22" rx="3" ry="4" width="6" height="8">
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8s"
            begin="-0.26666666666666666s"
            repeatCount="indefinite"
          />
        </rect>
      </g>
      <g transform="rotate(240 50 50)">
        <rect x="47" y="22" rx="3" ry="4" width="6" height="8">
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8s"
            begin="-0.17777777777777778s"
            repeatCount="indefinite"
          />
        </rect>
      </g>
      <g transform="rotate(280 50 50)">
        <rect x="47" y="22" rx="3" ry="4" width="6" height="8">
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8s"
            begin="-0.08888888888888889s"
            repeatCount="indefinite"
          />
        </rect>
      </g>
      <g transform="rotate(320 50 50)">
        <rect x="47" y="22" rx="3" ry="4" width="6" height="8">
          <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.8s" begin="0s" repeatCount="indefinite" />
        </rect>
      </g>
    </svg>
  );
};

export default SVG;
