import styled, { css } from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';
import { Link } from 'gatsby';
import { defaultTheme } from '../styles';

/* Primary Anchor */

const primaryLinkStyles = css`
  color: ${defaultTheme.text};
  font-family: var(--primary-font);
  font-size: 12px;
  transition: 0.2s;
  padding: 6px 8px;
  line-height: 1.75;
  &:hover {
    transition: 0.2s;
    background: ${defaultTheme.green};
  }
`;

export const PrimaryLinkGatsby = styled(Link)`
  ${primaryLinkStyles};
`;

export const PrimaryLinkAnchor = styled.a`
  ${primaryLinkStyles};
`;

/* Secondary Anchor */

const secondaryLinkModifiers = {
  onDark: () => `
    color: ${defaultTheme.textOnDarkLight};
    &:hover {
      color: ${defaultTheme.green};
  }
  `,
  medium: () => `
    font-size: 14px;
  `,
  large: () => `
    font-size: 18px;
  `,
};

const secondaryLinkStyles = css`
  color: ${defaultTheme.text};
  font-family: var(--primary-font);
  font-size: 12px;
  transition: 0.2s;
  line-height: 1.75;
  &:hover {
    transition: 0.2s;
    color: #000000;
  }
  ${applyStyleModifiers(secondaryLinkModifiers)}
`;

export const SecondaryLinkGatsby = styled(Link)`
  ${secondaryLinkStyles};
`;

export const SecondaryLinkAnchor = styled.a`
  ${secondaryLinkStyles};
`;
