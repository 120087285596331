/* eslint-disable import/prefer-default-export */

export const defaultTheme = {
  red: '#E50019',
  pink: '#EF476F',
  green: '#06D6A0',
  blue: '#2d3748',
  yellow: '#FFC857',
  text: '#2d3748',
  textLight: '#718096',
  textDark: '#000000',
  textOnDark: '#ffffff',
  textOnDarkLight: '#ebf8ff',
  black: '#333333',
  productImgBg: '#F7F8FC',
  productImgBgHover: '#F6FAFD',
  productImgBgPinned: '#edf2f7',
  footerBackground: '#515356',
  warning: '#F5C403',
  error: '#FF0201',
  success: '#00AF41',
  hr: '#cbd5e0',
  hrOnDark: '#616366',
  disabledBg: '#e2e8f0',
  // textInverted: neutrals[100],
  // textInvertedLight: neutrals[200],
  // textDisabled: neutrals[300],
  // lightBackground: neutrals[200],
  // navBarBackground: neutrals[400],
  // footerBackground: neutrals[400],
  // buttonPrimary: pink[200],
};
