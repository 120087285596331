/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';

const SVG = props => {
  return (
    <svg width="24px" viewBox="0 0 36 28" {...props}>
      <rect fill="#000000" fillOpacity="0" width="36" height="28" />
      <path d="M0,26H36V22H0ZM0,16H36V12H0ZM0,2V6H36V2Z" />
    </svg>
  );
};

export default SVG;
