import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Dialog } from './Dialog';
import { TertiaryButton as Button } from './Buttons';

const DialogBody = styled.div`
  font-family: var(--primary-font);
  font-size: var(--primary-font-size-base);
  line-height: 1.5;
  padding: 2rem 3rem;
  display: grid;
  justify-items: center;
  min-width: 320px;
`;

const Copy = styled.div`
  margin-bottom: 1rem;
`;

const ResponseDialog = ({ isShow, close, copy }) => {
  //
  return (
    <Dialog isShow={isShow}>
      <DialogBody>
        <Copy>{copy}</Copy>
        <div>
          <Button type="button" onClick={close}>
            CLOSE
          </Button>
        </div>
      </DialogBody>
    </Dialog>
  );
};

export default ResponseDialog;

ResponseDialog.propTypes = {
  isShow: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  copy: PropTypes.node.isRequired,
};
