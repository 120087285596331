/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';

const SVG = props => {
  return (
    <svg height="36px" viewBox="0 0 186.63 39.49" {...props}>
      <g>
        <rect fillOpacity="0" className="cls-1" width="186.63" height="39.49" />
        <g fill="#ed1c24">
          <path d="M144.83,6c-7.31,2.63-14.76,8.92-14.76,8.92s0-1.54-.15-3.07a5.91,5.91,0,0,0-1.18-2.78h-5.25c-.7.32,0,.68,0,.68a2.76,2.76,0,0,1,2.08.77c-.55,4-8,13.49-8,13.49a47.09,47.09,0,0,1-13.2,12.11c-4.39,3.19-8.29,1.6-8.29,1.6-1.15-.28-.82.49-.82.49a8.84,8.84,0,0,0,4,1c7.84.22,15.52-8.88,15.52-8.88,8.94-9.16,11.3-15.95,11.3-15.95.11-.49-.44-.28-.44-.28l-.23.39c-3.23,7.29-9.64,14-9.64,14-9.6,10.58-14.64,10-14.64,10,7.79-2.8,15.84-12.61,15.84-12.61a75.2,75.2,0,0,0,9.26-14.53c1.93,1.48.83,7.18.83,7.18a27,27,0,0,0-4.82,5.32c-1.87,3.24.33,3.46.49,3.46,5.21-.22,7.13-10,7.13-10l.08-.71.05-.83s1.71-1.49,2.5-2.08c1.07-.79,3.54-2.58,3.54-2.58L129.6,27c-.59,1-1.14,1-1.14,1-.67.11-.44.66-.44.66h5.27c.43-.38-.12-.72-.12-.72h-.38c-.5-.11-.44-1.37-.44-1.37L139.09,9.7c1.1-1.27,6-3.07,6-3.07A.52.52,0,0,0,144.83,6Zm-20.1,19.6c-1.13,1.23-1.72,1.13-1.82,1-1-1.53,3.25-5,3.25-5A6.63,6.63,0,0,1,124.73,25.6Z" />
          <path d="M130.27,16.27a37.16,37.16,0,0,1,3.83-3.06s.44-.06.17.38a40.76,40.76,0,0,0-4,3.24Z" />
          <path d="M140.24,9.64,133.83,26s-.11,1.37.55,1.92c0,0,.55.16.38-.44,0,0-.44-1.16-.22-1.64l6.31-15.95S140.79,9.2,140.24,9.64Z" />
        </g>
        <path d="M2.69,14.84c-.65-1.54-1.28-3-2.69-3v-.49H6.45v.49c-1.55,0-1.82.34-1.82,1a16.76,16.76,0,0,0,1.11,3l3.56,8.4,3.44-9.14A7.33,7.33,0,0,0,13.32,13c0-1-1.07-1.11-1.78-1.11v-.49h5v.49c-1.14,0-1.62.82-2,1.85-1.79,4.55-4.29,11.33-5.63,15H8.56Z" />
        <path d="M17.33,28.39v-.48c1.76,0,2.13-.81,2.13-1.56V13.42c0-.75-.37-1.57-2.13-1.57v-.48H30.2V15h-.54c-.27-1.39-1.06-2.64-2.76-2.64H23a1,1,0,0,0-1.06,1V19h3.41c1.62,0,3-.46,3-2.47h.52v6h-.52c0-2-1.38-2.49-3-2.49H21.94v6.34c0,.46.14,1,.62,1h3.2c1.93,0,3.78,0,5.07-3.29h.68l-1.1,4.33Z" />
        <path d="M46.69,28.75h-.36L35.54,14.59V26.33c0,.75.36,1.58,2.36,1.58v.48H32.12v-.48c2,0,2.36-.83,2.36-1.58v-13l-.2-.26c-.75-.9-1-1.14-2.16-1.16v-.49h4.15l9.35,12.36V13.43c0-.75-.36-1.57-2.35-1.57v-.49H49v.49c-2,0-2.35.82-2.35,1.57Z" />
        <path d="M60.12,28.39H53.39v-.48c1.76,0,2.13-.83,2.13-1.58V12.44H53.77c-1.78,0-2.9,0-3.43,3.3h-.59l.14-4.37H63.6l.15,4.37h-.59c-.53-3.3-1.64-3.3-3.43-3.3H58V26.33c0,.75.36,1.58,2.13,1.58Z" />
        <path d="M66.68,13.43c0-.75-.35-1.57-2.13-1.57v-.49H71.3v.49c-1.77,0-2.14.82-2.14,1.57v8.64c0,4,1.17,5.53,4.2,5.53,4.55,0,4.57-3.63,4.57-6.07v-8.1c0-.75-.36-1.57-2.28-1.57v-.49h5.62v.49c-1.91,0-2.28.82-2.28,1.57v8.1c0,2.9-.24,5.17-3,6.56a8.15,8.15,0,0,1-3.1.66c-4.43,0-6.23-2.15-6.23-6.86Z" />
        <path d="M86.52,13.55c0-1.2.22-1.2,1.79-1.2,2.18,0,3.88,1,3.88,3.55,0,2.77-2.16,3.54-5.67,3.67ZM84.05,26.33c0,.75-.37,1.58-2.07,1.58v.48h6.67v-.48c-1.77,0-2.13-.83-2.13-1.58V20.52l1.36-.05,5.76,7.92h3.9v-.48a3,3,0,0,1-2.31-1.45l-4.79-6.27c2.28-.34,4.38-1.6,4.38-4.32,0-3-2.42-4.5-6.44-4.5H82v.49c1.7,0,2.07.82,2.07,1.57Z" />
        <path d="M98.16,28.39v-.48c1.78,0,2.14-.81,2.14-1.56V13.42c0-.75-.36-1.57-2.14-1.57v-.48h12.89V15h-.54c-.27-1.39-1.07-2.64-2.77-2.64h-3.9a1,1,0,0,0-1.06,1V19h3.41c1.62,0,3-.46,3-2.47h.51v6h-.51c0-2-1.39-2.49-3-2.49h-3.41v6.34c0,.46.14,1,.63,1h3.19c1.94,0,3.79,0,5.07-3.29h.68l-1.09,4.33Z" />
        <path d="M146.53,11.68c3.79-.06,5.24,3.91,5.24,7.94.12,2.75-.89,7.58-5.24,7.93-2.89.24-5.24-2.64-5.24-7.93C141.29,14.71,142.88,11.93,146.53,11.68Zm0-.93c-4.77-.1-8,3.75-8,8.87,0,5.44,3.72,8.86,8,8.86,4.9,0,8-3.91,8-8.86C154.52,14.14,150.76,10.54,146.53,10.75Z" />
        <path d="M160.25,13.29c0-1.21.23-1.21,1.82-1.21,2.19,0,3.91,1,3.91,3.55,0,2.78-2.18,3.54-5.73,3.68Zm-2.49,12.78c0,.74-.37,1.57-2.08,1.57v.49h6.73v-.49c-1.79,0-2.16-.83-2.16-1.57V20.26l1.38-.06,5.82,7.93h3.93v-.49a3,3,0,0,1-2.32-1.44l-4.84-6.28c2.31-.33,4.42-1.6,4.42-4.32,0-3-2.44-4.49-6.5-4.49h-6.46v.49c1.71,0,2.08.82,2.08,1.56Z" />
        <path d="M172,28.13v-.49c1.79,0,2.16-.81,2.16-1.56V13.16c0-.76-.37-1.57-2.16-1.57V11.1h13v3.68h-.54a2.8,2.8,0,0,0-2.79-2.63h-3.95a1,1,0,0,0-1.08,1v5.53h3.45c1.65,0,3-.46,3-2.47h.51v6h-.51c0-2-1.4-2.49-3-2.49h-3.45v6.33c0,.47.15,1,.64,1h3.23c2,0,3.83,0,5.12-3.3h.69l-1.1,4.34Z" />
      </g>
    </svg>
  );
};

export default SVG;
