/* eslint-disable import/prefer-default-export */

import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import styled from 'styled-components';
import { AnimatePresence } from 'framer-motion';
import { newsletterValidation } from '../utils';
import { SecondaryInput as Input } from './Inputs';
import Chevron from './Svg/LeftChevron';
import { defaultTheme } from '../styles';
import { InputErrorMessage } from './InputErrorMessage';
import ResponseDialog from './ResponseDialog';

const errorMessageAnimation = {
  hidden: {
    opacity: 0,
    y: 10,
  },
  visible: {
    opacity: 1,
    y: 0,
  },
};

const Button = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  height: 24px;
  padding-top: 2px;
  &:focus {
    outline: 0;
  }
`;

const RightChevron = styled(Chevron)`
  height: 18px;
  fill: ${defaultTheme.green};
  transform: scale(-1, 1);
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  max-width: 260px;
  border: 1px ${defaultTheme.green} solid;
  padding: 6px 1rem;
  position: relative;
`;

export const Newsletter = () => {
  //
  const [response, setResponse] = useState({
    successIsShow: false,
    errorIsShow: false,
  });

  return (
    <>
      <Formik
        initialValues={{
          email: '',
        }}
        validationSchema={newsletterValidation}
        validateOnChange={false}
        onSubmit={async (values, { resetForm }) => {
          try {
            const res = await fetch('https://273jxc0zhl.execute-api.ap-southeast-1.amazonaws.com/prod/newsletter', {
              method: 'POST',
              body: JSON.stringify({
                email: values.email,
              }),
              headers: {
                'Content-type': 'application/json; charset=UTF-8',
              },
            });
            if (res.status === 200) {
              resetForm();
              setResponse({ ...response, successIsShow: true });
            }
          } catch (error) {
            setResponse({ ...response, errorIsShow: true });
          }
        }}
      >
        {({ values, errors, touched, isSubmitting, setValues, setFieldValue, handleChange, handleBlur, setErrors }) => {
          //
          const resetError = field => {
            setErrors({ ...errors, [field]: '' });
          };

          return (
            <Form>
              <InputWrapper>
                <Input
                  type="text"
                  placeholder="Enter your email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onFocus={e => {
                    resetError(e.target.name);
                  }}
                  aria-label="Enter your email for newsletter subscription"
                />
                <AnimatePresence>
                  {errors.email && (
                    <InputErrorMessage
                      initial="hidden"
                      animate="visible"
                      exit="hidden"
                      variants={errorMessageAnimation}
                      topPosition={-40}
                      arrowPosition={4}
                      onClick={() => resetError('email')}
                    >
                      {errors.email}
                    </InputErrorMessage>
                  )}
                </AnimatePresence>
                <Button type="submit" disabled={isSubmitting} aria-label="Sign up for newsletter">
                  <RightChevron />
                </Button>
              </InputWrapper>
            </Form>
          );
        }}
      </Formik>
      <ResponseDialog
        isShow={response.successIsShow}
        copy={<span>Thank you for subscribing.</span>}
        close={() => {
          setResponse({ ...response, successIsShow: false });
        }}
      />
      <ResponseDialog
        isShow={response.errorIsShow}
        copy={<span>Sorry. Submission failed. Please try again.</span>}
        close={() => {
          setResponse({ ...response, errorIsShow: false });
        }}
      />
    </>
  );
};
