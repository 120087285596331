/* eslint-disable import/prefer-default-export */

import { graphql, useStaticQuery } from 'gatsby';

export const useSiteMetadata = () => {
  const { site } = useStaticQuery(
    graphql`
      query SITE_METADATA_QUERY {
        site {
          ...SiteData
        }
      }
    `
  );
  return site.siteMetadata;
};
