/* eslint-disable import/prefer-default-export */

import React from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import PropTypes from 'prop-types';
import Locky from 'react-locky';
import { Overlay } from './Overlay';

const DialogBoxAnimation = {
  hidden: {
    opacity: 0,
    transform: 'translate(-50%, -10%)',
    transition: {
      duration: 0.2,
    },
  },
  open: {
    opacity: 1,
    transform: 'translate(-50%, -50%)',
    transition: {
      duration: 0.2,
    },
  },
};

const Wrapper = styled(motion.div)`
  position: fixed;
  z-index: 9001;
  background: #f7fafc;
  box-shadow: var(--elevation-4);
  display: grid;
  grid-row-gap: 1rem;
  left: 50%;
  top: 50%;
  border-radius: 8px;
  overflow: hidden;
`;

export const Dialog = ({ children, isShow }) => {
  //
  return (
    <>
      <AnimatePresence>
        {isShow && (
          <Locky>
            <Wrapper initial="hidden" animate="open" exit="hidden" variants={DialogBoxAnimation}>
              {children}
            </Wrapper>
            <Overlay />
          </Locky>
        )}
      </AnimatePresence>
    </>
  );
};

Dialog.propTypes = {
  isShow: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};
