import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { motion, AnimatePresence } from 'framer-motion';
import Locky from 'react-locky';
import {
  removeProductFromCart,
  increaseProductQuantity,
  decreaseProductQuantity,
  updateProductQuantity,
} from '../redux/actions';
import CartIcon from './Svg/Cart';
import { defaultTheme } from '../styles';
import AddIcon from './Svg/Add';
import RemoveIcon from './Svg/Remove';
import CancelIcon from './Svg/Cancel';
import { Overlay } from './Overlay';
import Spinner from './Svg/Spinner';
import {
  Header,
  Updating,
  ProductName,
  OptionInfo,
  Price as DisplayPrice,
  VariantQuantityInput as Input,
  DiscountedPrice as Discount,
  CartButton as Button,
} from './ProductSubComponents';
import { thousandSeparator } from '../utils';
import Close from './Svg/Close';

const drawerAnimation = {
  hidden: {
    opacity: 1,
    translateX: '100%',
    transition: {
      ease: 'easeInOut',
      duration: 0.1,
    },
  },
  visible: {
    opacity: 1,
    translateX: '0%',
    transition: {
      ease: 'easeInOut',
      duration: 0.1,
    },
  },
};

const lineItemAnimation = {
  initial: {
    x: 20,
    opacity: 0,
  },
  enter: {
    x: 0,
    opacity: 1,
  },
  exit: {
    x: 20,
    opacity: 0,
  },
};

const CloseButton = styled.button`
  position: absolute;
  top: 22px;
  right: 0;
  border: none;
  padding: 0 1rem 0;
  background: none;
  cursor: pointer;
  svg {
    height: 14px;
  }
  &:focus {
    outline: 0;
  }
`;

const Cart = styled.button`
  position: relative;
  z-index: 998;
  border: none;
  padding: 0 1rem 0;
  background: none;
  cursor: pointer;
  &:focus {
    outline: 0;
  }
`;

const Counter = styled.div`
  font-family: var(--primary-font);
  color: #ffffff;
  font-size: 11px;
  background: ${defaultTheme.red};
  width: 24px;
  height: 24px;
  padding-top: 7px;
  position: absolute;
  border-radius: 50%;
  font-weight: var(--primary-font-weight-bold);
  overflow: hidden;
  top: -8px;
  right: 0;
`;

const DrawerWrapper = styled(motion.div)`
  border-radius: 8px 0 0 8px;
  overflow: hidden;
  position: fixed;
  background: #f7fafc;
  z-index: 1001;
  width: 320px;
  height: 100vh;
  top: 0;
  right: 0;
  bottom: 0;
`;

const DrawerFooter = styled.div`
  padding: 1rem 0;
  position: fixed;
  width: 100%;
  bottom: 0;
  background: #ffffff;
  border-top: 1px #cbd5e0 solid;
`;

const CartLineItems = styled.div`
  overflow-y: scroll;
  max-height: 100vh;
  padding-top: 2rem;
  padding: 2rem 1rem 10rem 1rem;
  ::-webkit-scrollbar {
    display: none;
  }
  > div {
    border-bottom: 1px #cbd5e0 solid;
  }
`;

const LineItem = styled(motion.div)`
  padding: 1.5rem 0 2rem;
`;

const ProductNameHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  svg {
    height: 16px;
  }
`;

const PriceQuantity = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.5rem;
  div {
    display: flex;
    align-items: center;
  }
`;

const VariantType = styled(OptionInfo)``;

const CheckoutButton = styled.a`
  padding: 1rem 2rem;
  font-family: var(--primary-font);
  font-size: var(--primary-font-size-base);
  width: 100%;
  display: flex;
  background: ${defaultTheme.red};
  border-radius: 4px;
  justify-content: center;
  :link,
  :visited {
    color: #ffffff;
  }
`;

const SubTotal = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
`;

const Price = styled(DisplayPrice)`
  margin-left: 0.5rem;
`;

const DiscountedPrice = styled(Discount)`
  margin-left: 0.5rem;
`;

const Shop = () => {
  //
  const dispatch = useDispatch();
  const { isCartOpen, checkoutCart, client, isCartUpdating } = useSelector(state => state.shopify);

  const quantity = () => {
    let count = 0;
    checkoutCart.lineItems.forEach(lineItem => {
      count += parseInt(lineItem.quantity);
    });
    return count;
  };

  const confirmUpdate = (newQuantity, currentQuantity, lineItemId) => {
    //
    if (newQuantity && Number(newQuantity) !== currentQuantity && lineItemId) {
      dispatch(updateProductQuantity(client, checkoutCart, lineItemId, Number(newQuantity)));
    }
  };

  const handleKeyDown = (e, currentQuantity, lineItemId) => {
    if (e.key === 'Enter') {
      e.target.placeholder = e.target.value;
      e.target.blur();
      confirmUpdate(e.target.value, currentQuantity, lineItemId);
    }
  };

  return (
    <>
      <Cart
        type="button"
        onClick={() => {
          dispatch({ type: 'TOGGLE_CART_DRAWER' });
        }}
      >
        <CartIcon className="responsiveSvgColor" />
        <Counter>{checkoutCart && checkoutCart.lineItems ? quantity() : 0}</Counter>
      </Cart>
      <AnimatePresence>
        {isCartOpen && (
          <Locky>
            <DrawerWrapper initial="hidden" animate="visible" exit="hidden" variants={drawerAnimation}>
              <Header isCartUpdating={isCartUpdating}>
                {isCartUpdating ? (
                  <>
                    <Updating>Updating cart</Updating>
                    <Spinner />
                  </>
                ) : (
                  <ProductName>My Cart</ProductName>
                )}
                <CloseButton
                  type="button"
                  onClick={() => {
                    dispatch({ type: 'TOGGLE_CART_DRAWER' });
                  }}
                >
                  <Close />
                </CloseButton>
              </Header>
              <CartLineItems>
                <AnimatePresence>
                  {checkoutCart &&
                    checkoutCart.lineItems &&
                    checkoutCart.lineItems.map(lineItem => {
                      return (
                        <LineItem
                          key={lineItem.id}
                          initial="initial"
                          exit="exit"
                          animate="enter"
                          variants={lineItemAnimation}
                          positionTransition
                        >
                          <ProductNameHeader>
                            <ProductName>{lineItem.title}</ProductName>
                            <Button
                              type="button"
                              disabled={isCartUpdating}
                              onClick={() => dispatch(removeProductFromCart(client, checkoutCart, lineItem.id))}
                            >
                              <CancelIcon />
                            </Button>
                          </ProductNameHeader>
                          <VariantType>{lineItem.variant.title}</VariantType>
                          {lineItem.variant.compareAtPrice && (
                            <DiscountedPrice>
                              <strike>MYR ${lineItem.variant.compareAtPrice.slice(0, -3)}</strike>
                            </DiscountedPrice>
                          )}
                          <Price>
                            MYR <span>{lineItem.variant.price.slice(0, -3)}</span> <sub>each</sub>
                          </Price>
                          <PriceQuantity>
                            <div>
                              <Button
                                type="button"
                                disabled={isCartUpdating}
                                onClick={() =>
                                  dispatch(
                                    decreaseProductQuantity(client, checkoutCart, lineItem.id, lineItem.quantity)
                                  )
                                }
                              >
                                <RemoveIcon />
                              </Button>
                              <Input
                                type="number"
                                placeholder={lineItem.quantity}
                                onBlur={e => {
                                  confirmUpdate(e.target.value, lineItem.quantity, lineItem.id);
                                  e.target.placeholder = e.target.value || lineItem.quantity;
                                  e.target.value = '';
                                }}
                                onKeyDown={e => {
                                  handleKeyDown(e, lineItem.quantity, lineItem.Id);
                                }}
                              />
                              <Button
                                type="button"
                                disabled={isCartUpdating}
                                onClick={() =>
                                  dispatch(
                                    increaseProductQuantity(client, checkoutCart, lineItem.id, lineItem.quantity)
                                  )
                                }
                              >
                                <AddIcon />
                              </Button>
                            </div>
                            <div>
                              {lineItem.variant.compareAtPrice && (
                                <DiscountedPrice>
                                  <strike>MYR {parseFloat(lineItem.variant.compareAtPrice) * lineItem.quantity}</strike>
                                </DiscountedPrice>
                              )}
                              <Price>
                                MYR{' '}
                                <strong>
                                  {thousandSeparator(parseFloat(lineItem.variant.price) * lineItem.quantity)}
                                </strong>
                              </Price>
                            </div>
                          </PriceQuantity>
                        </LineItem>
                      );
                    })}
                </AnimatePresence>
              </CartLineItems>
              <DrawerFooter>
                <SubTotal>
                  <OptionInfo>Sub-Total</OptionInfo>
                  {parseFloat(checkoutCart.subtotalPrice) > 0 ? (
                    <>
                      <Price modifiers={['large']}>
                        MYR <span>{thousandSeparator(checkoutCart.subtotalPrice)}</span>
                      </Price>
                    </>
                  ) : (
                    <>
                      <Price modifiers={['large']}>
                        MYR <span>0.00</span>
                      </Price>
                    </>
                  )}
                </SubTotal>
                <div style={{ padding: '1rem' }}>
                  <CheckoutButton href={checkoutCart.webUrl}>Checkout</CheckoutButton>
                </div>
              </DrawerFooter>
            </DrawerWrapper>
            <Overlay
              onClick={() => {
                dispatch({ type: 'TOGGLE_CART_DRAWER' });
              }}
            />
          </Locky>
        )}
      </AnimatePresence>
    </>
  );
};

export default Shop;
