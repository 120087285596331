import styled, { css } from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';
import { breakpoints } from '../styles';

const baseStyles = css`
  color: #000000;
`;

const commonModifiers = {
  onDark: () => `
    h1,
    h2,
    h3 {
      color: #ffffff;
    }
  `,
};

const sectionHeaderModifiers = {
  small: () => `
    h1,
    h2 {
      font-size: var(--primary-font-size-level-3);
      @media (min-width: ${breakpoints.medium}px) {
      font-size: var(--primary-font-size-level-3);
      }
      @media (min-width: ${breakpoints.large}px) {
        font-size: var(--primary-font-size-level-3);
        margin-bottom: 1rem;
      }
    }
  `,
};

export const SectionHeader = styled.span`
  h1,
  h2 {
    ${baseStyles};
    font-family: var(--secondary-font);
    font-size: var(--secondary-font-size-level-3);
    font-weight: var(--secondary-font-weight-regular);
    line-height: 1;
    margin-bottom: 1.25rem;
    @media (min-width: ${breakpoints.medium}px) {
      font-size: var(--secondary-font-size-level-4);
    }
    @media (min-width: ${breakpoints.large}px) {
      font-size: var(--secondary-font-size-level-5);
      margin-bottom: 2.25rem;
    }
  }
  ${applyStyleModifiers(sectionHeaderModifiers)};
  ${applyStyleModifiers(commonModifiers)};
`;

const sectionItemHeaderModifiers = {
  small: () => `
    h2,
    h3 {
      font-size: var(--primary-font-size-base);
    }
  `,
};

export const SectionItemHeader = styled.span`
  h2,
  h3 {
    ${baseStyles};
    font-family: var(--primary-font);
    font-size: var(--primary-font-size-level-1);
    font-weight: var(--primary-font-weight-bold);
    line-height: 1.3;
    margin-bottom: 0.5rem;
    @media (min-width: ${breakpoints.large}px) {
      font-size: var(--primary-font-size-level-2);
    }
  }
  ${applyStyleModifiers(sectionItemHeaderModifiers)};
  ${applyStyleModifiers(commonModifiers)};
`;

export const Eyebrow = styled.h4`
  ${baseStyles};
  font-family: var(--primary-font);
  font-size: var(--primary-font-size-sublevel-1);
  font-weight: var(--primary-font-weight-regular);
  margin-bottom: 1rem;
`;

export const FormLabel = styled.p`
  ${baseStyles};
  font-family: var(--primary-font);
  font-weight: var(--primary-font-weight-regular);
  font-size: 14px;
`;
