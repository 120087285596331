/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';

const SVG = props => {
  return (
    <svg height="38px" viewBox="0 0 40 40" {...props}>
      <circle fill="#ffffff" cx="20" cy="20" r="19" />
      <path
        fill="#E50019"
        d="M34.69,7.1a13.83,13.83,0,0,0-3.81,2.27L23.19,28.62s-.07,1.43.5,1.56h.44s.62.38.13.81h-6a.54.54,0,0,1,.5-.75s.63,0,1.31-1.14l7.29-18.17-4,2.94c-.9.67-2.85,2.38-2.85,2.38l-.06.94-.1.81s-2.18,11.16-8.12,11.4c-.19,0-2.69-.25-.57-3.94a31,31,0,0,1,5.51-6.05s1.25-6.51-.95-8.19A86,86,0,0,1,5.63,27.78s-.84,1-2.25,2.52c.17.27.34.53.52.79l.34-.38s7.31-7.6,11-15.92l.25-.45s.63-.24.5.32c0,0-2.47,7.15-11.7,17A19.55,19.55,0,1,0,35.73,8.4,16,16,0,0,0,34.69,7.1ZM20.82,16.86a42.13,42.13,0,0,1,4.37-3.5s.5-.06.19.44a47.85,47.85,0,0,0-4.56,3.69ZM25.94,29.6c.19.68-.44.49-.44.49-.76-.62-.62-2.18-.62-2.18L32.19,9.29c.63-.51.69.25.69.25L25.69,27.71C25.44,28.27,25.94,29.6,25.94,29.6Z"
      />
      <path
        fill="#E50019"
        d="M6.36,25.65s8.46-10.82,9.08-15.38a3.12,3.12,0,0,0-2.38-.88s-.75-.38,0-.76h6a6.73,6.73,0,0,1,1.34,3.17c.17,1.74.17,3.5.17,3.5A72.45,72.45,0,0,1,34.14,6.5,19.55,19.55,0,0,0,3.05,29.75,46.34,46.34,0,0,0,6.36,25.65Z"
      />
      <path fill="#E50019" d="M12.43,28.59c.12.16.76.28,2.08-1.13a7.5,7.5,0,0,0,1.62-4.61S11.25,26.84,12.43,28.59Z" />
    </svg>
  );
};

export default SVG;
