import styled, { css } from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';
import { Link } from 'gatsby';
import { defaultTheme } from '../styles';

const normalize = css`
  cursor: pointer;
  font-family: var(--primary-font);
  &:focus {
    outline: 0;
  }
  &:hover {
  }
  &:active {
  }
  &:disabled {
    cursor: not-allowed;
    background: ${defaultTheme.disabledBg};
    color: ${defaultTheme.textLight};
    box-shadow: none;
    &:hover {
    }
  }
`;

const BaseButton = styled.button`
  ${normalize};
`;

const BaseGatsbyLink = styled(Link)`
  ${normalize};
`;

const BaseAnchor = styled.a`
  ${normalize};
`;

const commonModifiers = {
  full: () => `
    width: 100%;
    justify-content: center;
  `,
};

/* Primary Button */

const primaryButtonModifiers = {
  secondaryColor: () => `
    background: ${defaultTheme.blue};
  `,
};

const primaryButtonStyles = css`
  color: #ffffff;
  font-size: 0.875rem;
  font-weight: var(--primary-font-weight-regular);
  padding: 1.25rem 2.5rem;
  background: ${defaultTheme.red};
  display: inline-flex;
  align-items: center;
  box-shadow: var(--elevation-4);
  border: none;
  border-radius: 4px;
  transition: 0.3s;
  &:hover {
  }
  ${applyStyleModifiers(primaryButtonModifiers)};
  ${applyStyleModifiers(commonModifiers)};
`;

export const PrimaryButton = styled(BaseButton)`
  ${primaryButtonStyles};
`;

export const PrimaryButtonGatsby = styled(BaseGatsbyLink)`
  ${primaryButtonStyles};
`;

/* Secondary Button */

const secondaryButtonModifiers = {
  onDark: () => `
    color: #ffffff;
    &:hover {
      background: #ffffff;
      color: #000000;
      border-color: #ffffff;
    }
  `,
  small: () => `
    padding: 0 1.25rem;
    height: 2.5rem;
    font-size: 0.75rem;
  `,
  grayBorder: () => `
    border: 1px solid ${defaultTheme.hr};
    background: #ffffff;
    &:hover {
      border: 1px ${defaultTheme.green} solid;
  }
  `,
  raised: () => `
    border: 1px solid ${defaultTheme.hr};
    background: #ffffff;
    border: 1px ${defaultTheme.green} solid;
    box-shadow: var(--elevation-2);
  `,
};

const secondaryButtonStyles = css`
  border: 1px solid ${defaultTheme.green};
  color: #000000;
  font-size: 0.875rem;
  font-weight: var(--primary-font-weight-regular);
  padding: 1rem 2.5rem;
  background: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
  &:hover {
    /* background: ${defaultTheme.green}; */
    box-shadow: var(--elevation-2);
  }
  ${applyStyleModifiers(secondaryButtonModifiers)};
  ${applyStyleModifiers(commonModifiers)};
`;

export const SecondaryButton = styled(BaseButton)`
  ${secondaryButtonStyles};
`;

export const SecondaryButtonGatsby = styled(BaseGatsbyLink)`
  ${secondaryButtonStyles};
`;

export const SecondaryButtonAnchor = styled(BaseAnchor)`
  ${secondaryButtonStyles};
`;

/* Tertiary Button */

const tertiaryButtonModifiers = {};

const tertiaryButtonStyles = css`
  color: #ffffff;
  font-size: 11px;
  font-weight: var(--primary-font-weight-bold);
  padding: 0.75rem 1.5rem;
  background: ${defaultTheme.blue};
  display: inline-flex;
  align-items: center;
  border: none;
  border-radius: 4px;
  ${applyStyleModifiers(tertiaryButtonModifiers)};
  ${applyStyleModifiers(commonModifiers)};
`;

export const TertiaryButton = styled(BaseButton)`
  ${tertiaryButtonStyles};
`;

export const TertiaryButtonGatsby = styled(BaseGatsbyLink)`
  ${tertiaryButtonStyles};
`;
