/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';

const SVG = props => {
  return (
    <svg width="24px" viewBox="0 0 40 40" {...props}>
      <path
        fill="#ffffff"
        id="Facebook_Icon_"
        data-name="Facebook Icon "
        d="M20,40a20,20,0,1,1,14.14-5.86A20,20,0,0,1,20,40ZM16.35,17.3V20H18v7.82H21.2V20h2.17l.28-2.7H21.2V16c0-.68.05-1.07,1.09-1.07h1.35v-2.7H21.47A3.07,3.07,0,0,0,18,14.71a3.16,3.16,0,0,0,0,1V17.3Z"
      />
    </svg>
  );
};

export default SVG;
