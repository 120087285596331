/* eslint-disable import/prefer-default-export */

const initialState = {
  isOpen: false,
  background: '#ffffff',
  color: '#000000',
  boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);',
  svg: '#000000',
};

export const scrolled = {
  background: '#ffffff',
  color: '#000000',
  boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);',
  svg: '#000000',
};

export const navbarScrollDistance = 28;

export default (state = initialState, action) => {
  const { isOpen } = state;
  switch (action.type) {
    case 'NAV_INITIAL':
      return { ...state, ...action.value };
    case 'NAV_TOP':
      if (!isOpen) {
        return { ...state, ...action.value };
      }
      return { ...state };
    case 'NAV_SCROLLED':
      if (!isOpen) {
        return { ...state, ...scrolled };
      }
      return { ...state };
    case 'CLOSE_MOBILE_MENU':
      return { ...state, isOpen: false };
    case 'TOGGLE_MOBILE_MENU':
      if (!isOpen) {
        return { ...state, isOpen: !isOpen, color: '#000000', svg: '#000000' };
      }
      if (action.value.pos > navbarScrollDistance) {
        return { ...state, ...scrolled, isOpen: !isOpen };
      }
      if (action.value.pos < navbarScrollDistance) {
        return { ...state, ...action.value.style, isOpen: !isOpen };
      }
      return { ...state };
    default:
      return { ...state };
  }
};
