export const updateCheckoutCart = checkoutCart => {
  return {
    type: 'UPDATE_CHECKOUT_CART',
    checkoutCart,
  };
};

export const setClient = client => {
  return {
    type: 'SET_SHOPIFY_CLIENT',
    client,
  };
};

export const updatingCart = status => {
  return {
    type: 'SYNCING_CART',
    status,
  };
};

const getNewCheckoutCart = async (isBrowser, client) => {
  try {
    const cookie = isBrowser && JSON.parse(localStorage.getItem(process.env.GATSBY_SITE_URL));
    const checkoutCart = await client.checkout.create();
    localStorage.setItem(process.env.GATSBY_SITE_URL, JSON.stringify({ ...cookie, checkout_id: checkoutCart.id }));
    return checkoutCart;
  } catch (error) {
    //! console.error(error);
    return null;
  }
};

export const initializeCheckoutCart = (isBrowser, client) => {
  return async dispatch => {
    try {
      const cookie = isBrowser && JSON.parse(localStorage.getItem(process.env.GATSBY_SITE_URL));
      const checkoutId = cookie && cookie.checkout_id;
      let checkoutCart = null;
      if (checkoutId) {
        checkoutCart = await client.checkout.fetch(checkoutId);
        if (!checkoutCart || checkoutCart.completedAt) {
          checkoutCart = await getNewCheckoutCart(isBrowser, client);
        }
      } else {
        checkoutCart = await getNewCheckoutCart(isBrowser, client);
      }
      dispatch(updateCheckoutCart(checkoutCart));
    } catch (error) {
      //! console.error(error);
      return null;
    }
    return null;
  };
};

export const addProductToCart = (variantId, client, checkoutCart, quantity = 1) => {
  return async dispatch => {
    dispatch(updatingCart(true));
    try {
      const lineItems = [
        {
          variantId,
          quantity,
        },
      ];
      const updatedCheckoutCart = await client.checkout.addLineItems(checkoutCart.id, lineItems);
      dispatch(updateCheckoutCart(updatedCheckoutCart));
      dispatch(updatingCart(false));
    } catch (error) {
      //! console.error(error);
      return null;
    }
    return null;
  };
};

export const removeProductFromCart = (client, checkoutCart, lineItemIdToRemove) => {
  return async dispatch => {
    dispatch(updatingCart(true));
    try {
      const updatedCheckoutCart = await client.checkout.removeLineItems(checkoutCart.id, [lineItemIdToRemove]);
      dispatch(updateCheckoutCart(updatedCheckoutCart));
      dispatch(updatingCart(false));
    } catch (error) {
      //! console.error(error);
      return null;
    }
    return null;
  };
};

export const increaseProductQuantity = (client, checkoutCart, id, prevQuantity) => {
  return async dispatch => {
    dispatch(updatingCart(true));
    try {
      const quantity = prevQuantity + 1;
      const lineItemsToUpdate = [
        {
          id,
          quantity,
        },
      ];
      const updatedCheckoutCart = await client.checkout.updateLineItems(checkoutCart.id, lineItemsToUpdate);
      dispatch(updateCheckoutCart(updatedCheckoutCart));
      dispatch(updatingCart(false));
    } catch (error) {
      //! console.error(error);
      return null;
    }
    return null;
  };
};

export const decreaseProductQuantity = (client, checkoutCart, id, prevQuantity) => {
  return async dispatch => {
    dispatch(updatingCart(true));
    try {
      const quantity = prevQuantity - 1;
      const lineItemsToUpdate = [
        {
          id,
          quantity,
        },
      ];
      const updatedCheckoutCart = await client.checkout.updateLineItems(checkoutCart.id, lineItemsToUpdate);
      dispatch(updateCheckoutCart(updatedCheckoutCart));
      dispatch(updatingCart(false));
    } catch (error) {
      //! console.error(error);
      return null;
    }
    return null;
  };
};

export const updateProductQuantity = (client, checkoutCart, id, quantity) => {
  return async dispatch => {
    dispatch(updatingCart(true));
    try {
      const lineItemsToUpdate = [
        {
          id,
          quantity,
        },
      ];
      const updatedCheckoutCart = await client.checkout.updateLineItems(checkoutCart.id, lineItemsToUpdate);
      dispatch(updateCheckoutCart(updatedCheckoutCart));
      dispatch(updatingCart(false));
    } catch (error) {
      //! console.error(error);
      return null;
    }
    return null;
  };
};
