import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { useWindowScroll } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import { useScrollPosition } from '../utils';
import MobileMenu from './MobileMenu';
import Shop from './Shop';
import { defaultTheme } from '../styles';
import Mark from './Svg/Logomark';
import Type from './Svg/Logotype';
import { scrolled, navbarScrollDistance } from '../redux/reducers/layout-reducers';

const pages = [
  {
    label: 'Home',
    path: '/',
    link: false,
    background: 'transparent',
    color: '#000000',
    boxShadow: 'none',
    svg: '#000000',
  },
  {
    label: 'Our Products',
    path: '/products',
    link: true,
    background: '#ffffff',
    color: '#000000',
    boxShadow: 'none',
    svg: '#000000',
  },
  {
    label: 'Crops',
    path: '/crops',
    link: true,
    background: '#ffffff',
    color: '#000000',
    boxShadow: 'none',
    svg: '#000000',
  },
  {
    label: 'Contact Us',
    path: '/contact',
    link: true,
    background: 'transparent',
    color: '#000000',
    boxShadow: 'none',
    svg: '#000000',
  },
  {
    label: 'About Us',
    path: '/about',
    link: true,
    background: 'transparent',
    color: '#ffffff',
    boxShadow: 'none',
    svg: '#ffffff',
  },
  {
    label: '404',
    path: '/404',
    link: false,
    background: '#ffffff',
    color: '#ffffff',
    boxShadow: 'none',
    svg: '#000000',
  },
  {
    label: 'Sitemap',
    path: '/sitemap',
    link: false,
    background: '#ffffff',
    color: '#000000',
    boxShadow: 'none',
    svg: '#000000',
  },
  {
    label: 'Privacy Policy',
    path: '/privacy-policy',
    link: false,
    background: '#ffffff',
    color: '#000000',
    boxShadow: 'none',
    svg: '#000000',
  },
  {
    label: 'Terms of Use',
    path: '/terms-of-use',
    link: false,
    background: '#ffffff',
    color: '#000000',
    boxShadow: 'none',
    svg: '#000000',
  },
  {
    label: 'Cookie Policy',
    path: '/cookie-policy',
    link: false,
    background: '#ffffff',
    color: '#000000',
    boxShadow: 'none',
    svg: '#000000',
  },
];

const menuSwitchWidth = '840px';

const Wrapper = styled.nav`
  position: fixed;
  transition: 0.3s;
  width: 100%;
  z-index: 997;
  padding: 0.75rem 0;
  @media (min-width: 1100px) {
    padding: 1.25rem 0;
  }
  background: ${props => props.navState.background};
  box-shadow: ${props => props.navState.boxShadow};
  a {
    color: ${props => props.navState.color};
  }
  .responsiveSvgColor {
    fill: ${props => props.navState.svg};
  }
  .activeLink {
    color: #ffffff;
    background: ${defaultTheme.red};
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
  @media (min-width: 1232px) {
    padding: 0;
  }
`;

const LinkContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1.5rem;
  a {
    font-family: var(--primary-font);
    font-size: 15px;
    margin: 0 0.5rem 0;
    padding: 6px 12px;
    &:hover {
      color: #ffffff;
      background: ${defaultTheme.red};
    }
  }
  @media (max-width: ${menuSwitchWidth}) {
    display: none;
  }
`;

const Padding = styled.div`
  height: 66px;
`;

const NavGroupLeft = styled.div`
  position: relative;
  z-index: 999;
  display: flex;
  align-items: center;
  button {
    background: none;
    border: none;
    cursor: pointer;
    &:focus {
      outline: 0;
    }
  }
`;

const NavGroupRight = styled.div`
  display: flex;
`;

const Logomark = styled(Mark)`
  @media (min-width: 480px) {
    display: none;
  }
`;

const Logotype = styled(Type)`
  @media (max-width: 480px) {
    display: none;
  }
`;

const Navbar = ({ path }) => {
  //
  const linkBundle = pages.filter(page => {
    return page.link === true;
  });

  const dispatch = useDispatch();

  const [defaultNavState, setDefaultNavState] = useState({});

  const navState = useSelector(state => state.layout);

  useEffect(() => {
    const [state] = pages.filter(obj => {
      return obj.path === path;
    });
    dispatch({ type: 'NAV_INITIAL', value: { ...state } });
    setDefaultNavState(state);
  }, [path]);

  const { y } = useWindowScroll();

  useScrollPosition(
    ({ currPos, prevPos }) => {
      if (currPos.y > -navbarScrollDistance) {
        const newStyles = { ...navState, defaultNavState };
        if (JSON.stringify(newStyles) !== JSON.stringify(navState)) {
          dispatch({ type: 'NAV_TOP', value: defaultNavState });
        }
      }
      if (currPos.y < -navbarScrollDistance) {
        const newStyles = { ...navState, ...scrolled };
        if (JSON.stringify(newStyles) !== JSON.stringify(navState)) {
          dispatch({ type: 'NAV_SCROLLED' });
        }
      }
    },
    [navState]
  );

  const handleMobileMenu = () => {
    dispatch({
      type: 'TOGGLE_MOBILE_MENU',
      value: { pos: y, style: defaultNavState },
    });
  };

  return (
    <>
      <Wrapper navState={navState}>
        <Container>
          <NavGroupLeft>
            <Link
              to="/"
              aria-label="Back to home page"
              data-locky-group="mobileNav"
              onClick={() => {
                dispatch({ type: 'CLOSE_MOBILE_MENU' });
              }}
            >
              <Logomark className="responsiveSvgColor" />
              <Logotype className="responsiveSvgColor" />
            </Link>
            <LinkContainer>
              {linkBundle.map(link => (
                <Link key={link.path} to={link.path} activeClassName="activeLink" partiallyActive>
                  {link.label}
                </Link>
              ))}
            </LinkContainer>
          </NavGroupLeft>
          <NavGroupRight>
            <Shop />
            <MobileMenu onClick={handleMobileMenu} isOpen={navState.isOpen} menuSwitchWidth={menuSwitchWidth} />
          </NavGroupRight>
        </Container>
      </Wrapper>
      {defaultNavState && defaultNavState.background && defaultNavState.background === 'transparent' ? (
        <div />
      ) : (
        <Padding />
      )}
    </>
  );
};

export default Navbar;

Navbar.propTypes = {
  path: PropTypes.string.isRequired,
};
