/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';

const SVG = props => {
  return (
    <svg width="20px" viewBox="0 0 40 40" {...props}>
      <path d="M12,32a4,4,0,1,0,4,4A4,4,0,0,0,12,32ZM0,0V4H4l7.2,15.18-2.7,4.9A3.87,3.87,0,0,0,8,26a4,4,0,0,0,4,4H36V26H12.84a.5.5,0,0,1-.5-.5l.06-.24L14.2,22H29.1a4,4,0,0,0,3.5-2.06L39.76,7A2,2,0,0,0,40,6a2,2,0,0,0-2-2H8.42L6.54,0ZM32,32a4,4,0,1,0,4,4A4,4,0,0,0,32,32Z" />
    </svg>
  );
};

export default SVG;
