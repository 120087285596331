// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-crops-js": () => import("./../../../src/pages/crops.js" /* webpackChunkName: "component---src-pages-crops-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-templates-all-products-js": () => import("./../../../src/templates/allProducts.js" /* webpackChunkName: "component---src-templates-all-products-js" */),
  "component---src-templates-categorized-by-product-type-js": () => import("./../../../src/templates/categorizedByProductType.js" /* webpackChunkName: "component---src-templates-categorized-by-product-type-js" */),
  "component---src-templates-single-product-js": () => import("./../../../src/templates/singleProduct.js" /* webpackChunkName: "component---src-templates-single-product-js" */)
}

